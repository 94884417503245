*{
  font-family: 'Alkatra', cursive;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* userpage */
.bgSizeCover {
  background-size: cover;
  width: 100%;
  height: 600px;
  resize: both;
  overflow: scroll;
}

.centertext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section {
  position: relative;
  text-align: center;
  color: white;
  
}

.imagebtn {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 90%);
}

/* Cards page */
.card {
  box-shadow: 10px 10px 8px 2px #ddd;

}

.card-img-top {
  cursor: pointer;
  /* object-fit: contain; */
}

.bg-image {
  height: 260px;
  overflow: hidden;
  display: inline-block;
}
.bg-image img {
  height: 100%;
}

@media only screen and (max-width:767px){
  .centertext {
      top: 40%;
  }
  .centertext h1 {
      font-size: 24px;
  }
  .centertext br {
      display: none;
  }
  .centertext h5 {
      font-size: 18px;
      margin-top: 4px;
  }
  }
/* hr divider */
.hrdivider {
  border:none;
  height: 20px;
 	width: 90%;
	height: 50px;
	margin-top: 0;
	box-shadow: 0 25px 15px -10px #333;
  margin: -50px auto 10px;
}

/* subscribe */

.form-control {
  border-color: black;
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input {
  color: black;
  outline: none !important;
  box-shadow: none !important;
}

.sub{
  box-shadow: 0 25px 15px -10px #333;
}

/* recent post */

.textrecent {
  cursor: pointer;
}

/* Static page */

.static {
  text-align: justify;
  
}

.dynamicBody {
  display: flex; 
  justify-content: space-between; 
}

.dynamicImg {
  border-radius: 5px;
  flex: 0.3;
  width: 500px;
  max-height: 300px;
  max-width: 400px;
  margin: 20px 0px 10px 10px;
  border-style: solid;
  border-width: 0px;
  box-shadow: 6px 6px 12px 10px #9b9a9a;
}

.description {
  border-radius: 5px;
  font-size: 20px;
  line-height: 30px;
  margin: 20px 0px 10px 0px;
  padding: 30px 30px 30px 30px ;
  border-style: solid;
  border-width: 0px;
  box-shadow: 6px 6px 12px 10px #9b9a9a;

}

.comment {
  display: flex;
  justify-content: space-between; 
}

/* comments */

.commentpage {
  margin-top: -310px;
}


/* Admin */

.sidebar {
  min-width: 100px;
}

.sidebar :hover{
background-color: black;
}


body {
  background: #ecf0f3;
}

.wrapper {
  max-width: 350px;
  min-height: 500px;
  margin: 80px auto;
  padding: 40px 30px 30px 30px;
  background-color: #ecf0f3;
  border-radius: 15px;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
  width: 80px;
  margin: auto;
}

.logo img {
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 3px #5f5f5f,
      0px 0px 0px 5px #ecf0f3,
      8px 8px 15px #a7aaa7,
      -8px -8px 15px #fff;
}

.wrapper .name {
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 1.3px;
  padding-left: 10px;
  color: #555;
}

.wrapper .form-field input {
  width: 100%;
  display: block;
  border: none;
  outline: none;
  background: none;
  font-size: 1.2rem;
  color: #666;
  padding: 10px 15px 10px 10px;
  /* border: 1px solid red; */
}

.wrapper .form-field {
  padding-left: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.wrapper .form-field .fas {
  color: #555;
}

.wrapper .btn {
  box-shadow: none;
  width: 100%;
  height: 40px;
  background-color: black;
  /* #03A9F4 */
  color: #fff;
  border-radius: 25px;
  box-shadow: 3px 3px 3px #b1b1b1,
      -3px -3px 3px #fff;
  letter-spacing: 1.3px;
}

.wrapper .btn:hover {
  background-color: #4a4d4e;
}

.wrapper a {
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
}

.wrapper a:hover {
  color: #4a4d4e;
}

@media(max-width: 380px) {
  .wrapper {
      margin: 30px 20px;
      padding: 40px 15px 15px 15px;
  }
}

.adminNavBody {
  width: 100%;
}
.adminNavLeft {
  display: inline-block;
}
.adminNavRight {
  float: right;
}